a.btn.btn-danger, a.btn.btn-danger:hover, a.btn.btn-danger:visited {
  color: #fff;
}

a.btn {
  color: #333;
}

.btn {
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 1px #ffffffbf;
  vertical-align: middle;
  cursor: pointer;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
  background-image: -o-linear-gradient(top, #fff, #e6e6e6);
  background-color: #f5f5f5;
  background-image: linear-gradient(#fff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #0000001a;
  border-bottom-color: #a2a2a2;
  border-radius: 4px;
  margin-bottom: 0;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px #fff3, 0 1px 2px #0000000d;
  box-shadow: inset 0 1px #fff3, 0 1px 2px #0000000d;
}

.btn:hover, .btn:active, .btn.active, .btn.disabled, .btn[disabled] {
  color: #333;
  background-color: #e6e6e6;
}

.btn:active, .btn.active {
  background-color: #ccc \9 ;
}

.btn:hover {
  color: #333;
  -o-transition: background-position .1s linear;
  background-position: 0 -15px;
  text-decoration: none;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  transition: background-position .1s linear;
}

.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn.active, .btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px #00000026, 0 1px 2px #0000000d;
  box-shadow: inset 0 2px 4px #00000026, 0 1px 2px #0000000d;
}

.btn.disabled, .btn[disabled] {
  cursor: default;
  opacity: .65;
  filter: alpha(opacity= 65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-image: none;
}

.btn-large {
  border-radius: 6px;
  padding: 11px 19px;
  font-size: 17.5px;
}

.btn-large [class^="icon-"], .btn-large [class*=" icon-"] {
  margin-top: 4px;
}

.btn-small {
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 11.9px;
}

.btn-small [class^="icon-"], .btn-small [class*=" icon-"] {
  margin-top: 0;
}

.btn-mini [class^="icon-"], .btn-mini [class*=" icon-"] {
  margin-top: -1px;
}

.btn-mini {
  text-transform: none;
  border-radius: 3px;
  padding: 0 6px;
  font-size: 10.5px;
  font-weight: normal;
}

.btn-block {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.btn-primary.active, .btn-warning.active, .btn-danger.active, .btn-success.active, .btn-info.active, .btn-inverse.active {
  color: #ffffffbf;
}

.btn {
  border-color: #00000026 #00000026 #00000040;
}

.btn-primary {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(top, #08c, #04c);
  background-image: -o-linear-gradient(top, #08c, #04c);
  background-color: #006dcc;
  background-image: linear-gradient(#08c, #04c);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
  color: #fff;
  background-color: #04c;
}

.btn-primary:active, .btn-primary.active {
  background-color: #039 \9 ;
}

.btn-warning {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-color: #faa732;
  background-image: linear-gradient(#fbb450, #f89406);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-warning:hover, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
  color: #fff;
  background-color: #f89406;
}

.btn-warning:active, .btn-warning.active {
  background-color: #c67605 \9 ;
}

.btn-danger {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
  background-color: #da4f49;
  background-image: linear-gradient(#ee5f5b, #bd362f);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-danger:hover, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled] {
  color: #fff;
  background-color: #bd362f;
}

.btn-danger:active, .btn-danger.active {
  background-color: #942a25 \9 ;
}

.btn-success {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
  background-image: -webkit-linear-gradient(top, #62c462, #51a351);
  background-image: -o-linear-gradient(top, #62c462, #51a351);
  background-color: #5bb75b;
  background-image: linear-gradient(#62c462, #51a351);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-success:hover, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
  color: #fff;
  background-color: #51a351;
}

.btn-success:active, .btn-success.active {
  background-color: #408140 \9 ;
}

.btn-info {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-color: #49afcd;
  background-image: linear-gradient(#5bc0de, #2f96b4);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
  color: #fff;
  background-color: #2f96b4;
}

.btn-info:active, .btn-info.active {
  background-color: #24748c \9 ;
}

.btn-inverse {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444), to(#222));
  background-image: -webkit-linear-gradient(top, #444, #222);
  background-image: -o-linear-gradient(top, #444, #222);
  background-color: #363636;
  background-image: linear-gradient(#444, #222);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-inverse:hover, .btn-inverse:active, .btn-inverse.active, .btn-inverse.disabled, .btn-inverse[disabled] {
  color: #fff;
  background-color: #222;
}

.btn-inverse:active, .btn-inverse.active {
  background-color: #080808 \9 ;
}

button.btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="submit"].btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.btn-link, .btn-link:active, .btn-link[disabled] {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #0000;
  background-image: none;
}

.btn-link {
  color: #08c;
  cursor: pointer;
  border-color: #0000;
  border-radius: 0;
}

.btn-link:hover {
  color: #005580;
  background-color: #0000;
  text-decoration: underline;
}

.btn-link[disabled]:hover {
  color: #333;
  text-decoration: none;
}

.btn-group {
  white-space: nowrap;
  vertical-align: middle;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.btn-group + .btn-group {
  margin-left: 5px;
}

.btn-toolbar {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0;
}

.btn-toolbar > .btn + .btn, .btn-toolbar > .btn-group + .btn, .btn-toolbar > .btn + .btn-group {
  margin-left: 5px;
}

.btn-group > .btn {
  border-radius: 0;
  position: relative;
}

.btn-group > .btn + .btn {
  margin-left: -1px;
}

.btn-group > .btn, .btn-group > .dropdown-menu, .btn-group > .popover {
  font-size: 14px;
}

.btn-group > .btn-mini {
  font-size: 10.5px;
}

.btn-group > .btn-small {
  font-size: 11.9px;
}

.btn-group > .btn-large {
  font-size: 17.5px;
}

.btn-group > .btn:first-child {
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 0;
}

.btn-group > .btn:last-child, .btn-group > .dropdown-toggle {
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btn-group > .btn.large:first-child {
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-left: 0;
}

.btn-group > .btn.large:last-child, .btn-group > .large.dropdown-toggle {
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active {
  z-index: 2;
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  -webkit-box-shadow: inset 1px 0 #ffffff20, inset 0 1px #fff3, 0 1px 2px #0000000d;
  box-shadow: inset 1px 0 #ffffff20, inset 0 1px #fff3, 0 1px 2px #0000000d;
}

.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
}

.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px #00000026, 0 1px 2px #0000000d;
  box-shadow: inset 0 2px 4px #00000026, 0 1px 2px #0000000d;
}

.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}

.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #04c;
}

.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}

.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}

.btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}

.btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}

.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #222;
}

.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}

.btn-mini .caret, .btn-small .caret, .btn-large .caret {
  margin-top: 6px;
}

.btn-large .caret {
  border-top-width: 5px;
  border-left-width: 5px;
  border-right-width: 5px;
}

.dropup .btn-large .caret {
  border-bottom-width: 5px;
}

.btn-primary .caret, .btn-warning .caret, .btn-danger .caret, .btn-info .caret, .btn-success .caret, .btn-inverse .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.btn-group-vertical {
  display: inline-block;
}

.btn-group-vertical > .btn {
  float: none;
  border-radius: 0;
  max-width: 100%;
  display: block;
}

.btn-group-vertical > .btn + .btn {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:first-child {
  border-radius: 4px 4px 0 0;
}

.btn-group-vertical > .btn:last-child {
  border-radius: 0 0 4px 4px;
}

.btn-group-vertical > .btn-large:first-child {
  border-radius: 6px 6px 0 0;
}

.btn-group-vertical > .btn-large:last-child {
  border-radius: 0 0 6px 6px;
}
/*# sourceMappingURL=button.7ac9ab61.css.map */
